$colors: (
    dark-grey: #323232,
    dark-grey-hover: rgba(#323232, 0.8),
    blue: #1700b8,
    blue-hover: #100081,
    light-blue: #6088d5,
    light-blue-hover: #4f6eab,
    orange:  #ffb78e,
    orange-hover: #ffa564,
    black: #101010,
    main-grey: #191919,
    mid-grey: #646464,
    silver-grey: #b3b3b3,
    silver-light-grey: #dedede,
    light-grey: #f2f2f2,
    white: #ffffff,
    sun: #f0c356,
    tomato: #ed767a,
    sky: #56a8c2,
    apple: #8dc875,
    lavander: #bf87e1,
    neon: #7474ee,
);
