@import 'erpcore/assets/scss/main';

.input-number {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
    margin-bottom: $spacing;

    &__content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
    }

    &__error {
        font: $font-label;
        width: 100%;
        margin-left: $spacing * 2;
        margin-top: $spacing;
        color: getColor(tomato);
    }

    &__col {
        width: 64px;

        &.is-btn {
            width: 40px;
        }
    }

    &__field {
        margin: 0;
        width: 100%;
        border: none;
        height: 40px;
        padding: 0 3px;
        font-size: 16px;
        border-radius: 0;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        color: getColor(black);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }

        &[disabled],
        &[disabled='disabled'] {
            opacity: 0.6;
            background-color: getColor('light-grey');
        }
    }

    &__btn {
        width: 40px;
        height: 40px;
        display: flex;
        font-size: 12px;
        cursor: pointer;
        flex-wrap: nowrap;
        border-radius: 2px;
        align-items: center;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        border: 2px solid getColor('light-grey');
        background-color: transparent;

        &:hover {
            color: getColor(orange);
        }

        &[disabled],
        &[disabled='disabled'] {
            pointer-events: none;
            background-color: getColor('light-grey');
        }
    }

    &__label {
        display: block;
        width: 100%;
        margin-bottom: $spacing;
        color: getColor(silver-grey);
    }

    &--required {
        &:after {
            content: '*';
            position: absolute;
            right: -11px;
            top: 0px;
            font: $font-form;
            color: getColor(silver-grey);
        }
    }
}
