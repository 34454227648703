@import 'erpcore/assets/scss/main';

.main-navigation {
    $root: &;
    width: 100%;
    padding: 0 $spacing ($spacing * 3) $spacing;

    &__menu {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &-item {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;

            &--open {
                #{$root}__submenu {
                    height: auto;
                }

                #{$root}__menu-link-badge {
                    transform: rotate(180deg);
                }
            }
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;
            width: 100%;
            font: $font-body;
            text-align: left;
            color: getColor(black);
            margin: 0;
            padding: $spacing ($spacing * 2);
            border: none;
            border-radius: 3px;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                color: getColor(orange-hover);
                text-decoration: none;
            }

            &-badge {
                font: $font-label;
                color: getColor(silver-grey);
                background-color: transparent;
                transition: getTransition();

                .svg {
                    display: block;
                    width: $spacing;
                    height: $spacing;
                    fill: getColor(black);
                }
            }

            &--active {
                color: getColor(orange);
                background-color: getColor(light-grey);
            }
        }
    }

    &__submenu {
        height: 0;
        padding: 0;
        list-style-type: none;
        overflow: hidden;

        &-item {
            padding: 0;
        }

        &-link {
            display: block;
            padding: $spacing ($spacing * 2) $spacing ($spacing * 4);
            color: getColor(black);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: getColor(orange-hover);
                text-decoration: none;
            }

            &--active {
                color: getColor(orange);
                background-color: getColor(light-grey);
            }
        }
    }
}
