@import 'erpcore/assets/scss/main';

.events-listing {
    &__name {
        display: flex;

        &-image {
            width: 45px;
            flex-basis: 45px;
            min-width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: getColor('light-grey');
            margin-right: $spacing * 2;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            &--placeholder {
                img {
                    width: 60%;
                    height: auto;
                }
            }
        }

        &-content {
            h4 {
                margin-bottom: 0;
            }

            p {
                margin-top: $spacing;
                font-weight: normal;
            }
        }
    }

    &__welcome-email-badge {
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: $spacing;

        &--red {
            fill: getColor(orange);
        }

        &--apple {
            fill: getColor(apple);
        }
    }

    &__event-running,
    &__event-paused {
        margin: auto $spacing;
    }

    &__event-running {
        color: getColor(apple);
    }

    &__event-paused {
        color: getColor(sun);
    }

    &__activity-mobile {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }
}