@import 'erpcore/assets/scss/main';

.main {
    width: 100%;
    height: 0;
    display: block;
    text-align: left;
    font: $font-list;
    flex-wrap: nowrap;
    padding: 0 0 100%;
    position: relative;
    color: getColor(white);
    border-radius: $spacing / 2;
    background-color: getColor(mid-grey);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        opacity: 0;
        content: '\A';
        visibility: hidden;
        position: absolute;
        border-radius: $spacing / 2;
        background-color: getColor(black);
        transition: getTransition();
    }

    &:after {
        right: 0;
        width: 0;
        height: 0;
        bottom: 0;
        z-index: 10;
        content: '\A';
        display: block;
        position: absolute;
        border-width: 10px;
        border-style: solid;
        border-radius: 2px 0 0 0;
        border-color: rgba(255, 255, 255, 0.35) #fff #fff rgba(255, 255, 255, 0.35);
    }

    &:hover {
        &:before {
            opacity: 0.75;
            visibility: visible;
        }

        .actions,
        .mainTop,
        .mainBottom {
            opacity: 1;
            visibility: visible;
        }
    }

    @include fileTypesBackground;
}

.mainContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $spacing;
}

.mainTop {
    z-index: 5;
    width: 100%;
    display: block;
    position: relative;
}

.mainBottom {
    z-index: 5;
    width: 100%;
    display: block;
    position: relative;
}

.mainBottomRight {
    z-index: 3;
    width: 100%;
    display: block;
    position: relative;
}

.actions {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 5;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    padding: $spacing * 2 0;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();

    & > ul {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        list-style-type: none;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    :global {
        .table-actions {
            &__item {
                padding: 0;
                background-color: transparent;

                a,
                button,
                .button {
                    border-radius: 100%;
                    width: $spacing * 4;
                    height: $spacing * 4;
                    background-color: transparent;

                    &:hover {
                        background-color: getColor(orange);
                    }

                    svg path {
                        fill: getColor(white);
                    }
                }
            }
        }
    }
}

.title {
    margin: 0;
    width: 100%;
    font: $font-4;
    display: block;
    padding: 2px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.is-image {
    background-color: getColor(white);
    border: 1px solid getColor(light-grey);

    &:after {
        display: none;
    }

    .mainTop,
    .mainBottom {
        opacity: 0;
        visibility: hidden;
        transition: getTransition();
    }
}
