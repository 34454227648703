@import 'erpcore/assets/scss/main';

.page-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 10000;

    .svg {
        width: 80px;
        height: 80px;
    }

    .dot1 {
        fill: getColor(light-blue);
    }

    .dot2 {
        fill: getColor(orange);
    }

    .dot3 {
        fill: getColor(dark-grey);
    }

    &--content {
        position: absolute;
        align-items: flex-start;
        padding-top: $spacing * 20;
        z-index: 899;
    }
}
